<template>
    <div class="container container-tight">
        <div class="block"></div>

        <div class="container">
            <div class="ts-measure ts-measure-center">
                <h1 class="page-title">{{ $t("page_title") }}</h1>
            </div>
        </div>
        <div v-if="error" class="ts-align-center">
            {{ $t("error.invalid_link") }}
        </div>
        <div class="block" v-else>
            <LoaderWrapper :loading="loading" radius="80px">
                <div class="panel">
                    <div class="panel-header">
                        <h3 class="panel-title">{{ $t("label.review_form") }}</h3>
                    </div>
                    <div class="panel-body" v-if="!success">
                        <div class="mb-2">
                            <div class="mb-1">
                                <strong>{{ $t("label.used_in") }}</strong>
                            </div>
                            <div class="flex flex-wrap">
                                <div
                                    v-for="country in data.countries"
                                    :key="country.id"
                                    class="mb-1"
                                >
                                    <picture class="flag mr-1">
                                        <source
                                            :srcset="`/img/flags/${country.id.toLowerCase()}.webp`"
                                            type="image/webp"
                                        />
                                        <source
                                            :srcset="`/img/flags/${country.id.toLowerCase()}.png`"
                                            type="image/png"
                                        />
                                        <img
                                            class="flag "
                                            src="/img/flags/earth.png"
                                            :alt="country.name"
                                        />
                                    </picture>
                                    <span class="mr-2">{{ country.name }}</span>
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent="send">
                            <div class="mb-2 form-error">
                                <strong class="mr-2">{{ $t("label.review_rating") }} *</strong>
                                <VueStars
                                    class="rating"
                                    name="demo"
                                    active-color="#ed9c26"
                                    inactive-color="#999999"
                                    hover-color="#ed9c26"
                                    :max="5"
                                    v-model="review.rating"
                                    :readonly="false"
                                    char="★"
                                    inactive-char=""
                                />
                                <div
                                    v-if="rateError"
                                    class="helper-block error"
                                    v-text="$t('error.please_rate')"
                                ></div>
                            </div>

                            <BaseInput
                                :label="$t('label.review_name') + ' *'"
                                v-model="review.name"
                                name="name"
                                type="text"
                                required
                            ></BaseInput>

                            <BaseInput
                                :label="$t('label.review_text')"
                                v-model="review.review"
                                type="textarea"
                                name="review"
                            ></BaseInput>

                            <div class="row">
                                <button type="submit" class="button row-push button-primary">
                                    {{ $t("button.send") }}
                                </button>
                            </div>
                        </form>
                        <p class="ts-small">{{ $t("publicly_displayed") }}</p>
                    </div>
                    <div class="panel-body" v-else>
                        <h3>{{ $t("thanks.title") }}</h3>
                        <p class="ts-quiet">
                            {{ $t("thanks.text") }}
                        </p>
                        <i18n-link class="button row-push button-primary" :to="{ name: 'home' }">{{
                            $t("button.back_to_home")
                        }}</i18n-link>
                    </div>
                </div>
            </LoaderWrapper>
        </div>
    </div>
</template>
<script>
import BaseInput from "@/components/base/BaseInput"
import LoaderWrapper from "@/components/LoaderWrapper"
import VueStars from "vue-stars/src/components/VueStars"
import { load } from "recaptcha-v3"

export default {
    components: {
        BaseInput,
        LoaderWrapper,
        VueStars,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
        })
    },
    data() {
        return {
            data: {},
            error: null,
            rateError: null,
            review: {
                name: "",
                title: "",
                review: "",
                rating: 0,
            },

            success: false,
            loading: false,
        }
    },
    mounted() {
        console.log(this.$route.params)
        this.fetch()
    },
    methods: {
        async fetch() {
            this.loading = true
            try {
                const response = await fetch(
                    `${this.$apiURL}/public/review?token=${this.$route.params.token}`,
                    { method: "GET" }
                )
                if (response.status != 200) {
                    this.loading = false
                    this.error = "invalid_link"
                    return
                }
                this.data = await response.json()
                //this.review.name = this.data.customerName
            } catch (error) {
                this.error = "invalid_link"
            }
            this.loading = false
        },
        async send() {
            this.loading = true
            this.rateError = null
            if (this.review.rating <= 0) {
                this.rateError = true
                this.loading = false
                return
            }

            try {
                const response = await fetch(`${this.$apiURL}/public/review`, {
                    method: "PUT",
                    body: JSON.stringify({ ...this.review, token: this.$route.params.token }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                const result = await response.json()

                if (result.message === "Success") {
                    this.loading = false
                    this.success = true
                } else {
                    this.success = false
                    this.loading = false
                    this.error = true
                }
            } catch (e) {
                console.log(e.toString())
            }
        },
    },
    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Review Form",
                },
                publicly_displayed: "This review might be publicly displayed.",
                page_title: "Review",
                label: {
                    review_form: "Write a Review",
                    used_in: "You used Stork Mobile eSIM in",
                    review_rating: "Rating",
                    review_name: "Your name (nickname)",
                    review_title: "Title",
                    review_text: "Write your review here",
                },
                thanks: {
                    title: "Thank you!",
                    text: "We appreciate the time you take to give your review.",
                },
                error: {
                    please_rate: "Please choose your rating",
                    invalid_link: "Invalid link",
                },
            },
            ja: {
                meta: {
                    title: "レビューフォーム",
                },
                publicly_displayed: "レビューはインターネット上に公開されることがあります。",
                page_title: "レビュー",
                label: {
                    review_form: "レビューを残す",
                    used_in: "eSIMをご利用された国",
                    review_rating: "5段階評価（レート）",
                    review_name: "レビュー投稿者名（ニックネーム可）",
                    review_title: "タイトル",
                    review_text: "本文",
                },
                thanks: {
                    title: "Thank you!",
                    text: "レビューいただきありがとうございました。",
                },
                error: {
                    please_rate: "5段階評価（レート）をつけてください",
                    invalid_link: "無効なリンク",
                },
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.rating {
    font-size: 23px;
}
</style>
